$color_1: #4ecca3;
$color_2: rgb(136, 146, 176);
$color_3: #eeeeee;

.main-container { 
    display: flex;
    width: 100%; 
}

.left-container {
    width: 15%;
    
}

.mid-container {
    width: 70%;
    margin-left: 15%;

    p {
        font-family: monospace;
        color: $color_1;
        font-size: 1.25rem;
        margin-bottom: 0px;
    }

    .name-text {
        color: $color_3;
        font-size: 5.5rem;
        font-family: 'calibre';
        line-height: 4rem;  
        margin-top: 5%;
        margin-bottom: 0;
        
    }

    .second-text {
        color: rgb(177, 177, 177);
        font-size: 5.5rem;
        line-height: 4rem;
        margin-top: 2.5%;
    }

    .third-text {
        margin-top: 50px;
        width: 50%;
        color:  rgb(177, 177, 177);
        line-height: 2.0;
        font-family: sans-serif;
        font-size: 1rem;
    }

}


.right-container {
    width: 15%;
}

@font-face {
    font-family: 'calibre';
    src: url(../CalibreBlack.otf) format('opentype');
}

.Get-In-Touch {
    background-color:  #232931;
    color: $color_1;
    border: 1px solid $color_1;
    font-family: monospace;
    font-size: 1.2rem;
    padding: 1rem;
    margin-top: 5%;
}

.Get-In-Touch:hover {
    background-color: $color_1;
    color: white;
    transition: all 0.5s
}

html {
    scroll-behavior: smooth;
}