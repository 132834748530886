$color_3: #eeeeee;

.NavContainer {
    display: flex;
    justify-content: flex-start;
    .logo {
        height: 2.5%;
        width: 2.5%;
        min-height: 3.5%;
        min-width: 3.5%;
        margin: 2%;
        opacity: 1;
	    animation-name: fadeInOpacity;
	    animation-iteration-count: 1;
	    animation-timing-function: ease-in;
        animation-duration: .5s;
    }
    svg:hover{
        #inner-Shape {
            fill: #4ecca3;
            opacity: 0.1;
        }
    }
    ul {
        display: flex;
        margin-top: 3.0%;
        margin-left: 50%;
        font-family: monospace;
        font-size: 13px;
        li {
            display: flex;
            list-style: none; 
            color: $color_3;
            margin-right: 10%;

            div {
                color: #4ecca3;
                margin-right: 10%;
            }
            
        }
        li:hover {
            color: #4ecca3
        }
    }
}

.first {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
    
}

.second {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1.5s;
    
}

.third {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    
}

.fourth {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2.5s;
    
}

.fifth {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 3s;
    
}


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


.icon {
    display: none;
    color: #4ecca3;
    margin-left: 85%;
    margin-top: 2.5%;
  }


@media screen and (max-width: 600px) {
    .NavContainer ul {display: none;}
    .NavContainer a.icon {
      display: block;
      float: right;
    }
  }

  @media screen and (max-width: 600px) {
    .NavContainer.responsive {position: relative;}
    .NavContainer.responsive a.icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .NavContainer.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }

  .resume-button {
      background-color: #232931;
      color: #4ecca3;
      height: 50%;
      width: 100%;
      font-size: 12px;
      border: 1px solid #4ecca3;
  }

  .resume-button:hover {
      background-color: #3d6759
  }

  .react-slidedown.my-dropdown-slidedown {
    transition-duration: 2s;
}

