$color_1: #4ecca3;
$color_2: rgb(136, 146, 176);
$color_3: #eeeeee;

.left-container {
    width: 40px;
    position: fixed;
    bottom: 0px;
    left: -50px;
    right: auto;
    z-index: 10;
    color: rgb(168, 178, 209);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.linked-in {
    fill: $color_2;
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

.github {
    fill: $color_2;
    width: 20px;
    height: 20px;
   margin-bottom: 10px;
}

.angel {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    g {
        path {
            fill: $color_2;
        }
    }
    height: 30px;
}

.linked-in:hover, .github:hover, .angel:hover {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    fill:$color_1;
    display: inline-block;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transform: translateY(-3px);
}


.angel:hover {
    
    g {
        path {
            fill: $color_1
        }
    }
    
}



.left-container:after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    background-color: rgb(168, 178, 209);
    margin: 0px auto;
}




