$color_1: #4ecca3;
$color_2: rgb(136, 146, 176);
$color_3: #eeeeee;



.About-Me-Container {
    margin-top: 33.3%;
    .About-me-header {
        color: $color_3;
        font-family: 'calibre';
        font-size: 2rem;
    }

    .About-me-header:before {
        content: "01.";
        margin-right: 10px;
        color: $color_1;
        font-family: monospace;
    }

    .About-me-header:after {
        content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(45, 57, 82);
    position: relative;
    top: -5px;
    margin-left: 20px;
    }

}

.prof-container {
    display: flex;
    .bio-text {
        width: 60%;
        color: $color_2;
        font-family: sans-serif;
        line-height: 1.5;
        font-size: 1.0rem;
        max-width: 480px;
    }
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: flex-start;
    
}


.abc{
    border: 1px solid $color_1;
        position: relative;
    right: -230px;
    width: 40%;
    height: 300px;
    top: 0px;
}



.tech-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    overflow: hidden;
    padding: 0px;
    margin: 20px 0px 0px;
    list-style: none;
    li {
        font-family: monospace;
        font-size: 1.0rem;
        line-height: 1.5rem;
    }

    margin-left: 5% ;

}

.tech-item::before {
        content: "▹";
        position: relative;     
        color: $color_1;
        font-size: 14px;
        line-height: 12px;
        left: 0px;
        margin-right: 10px;
        
}

.funny-gif {
    position: relative;
    top: 0px;
    left: -8px;
    height: 10%;
    width: 90%;
}


.funny-cont {
    width: 40%;
}

.Experience-Container {
    margin-top: 33% ;
    
    .experience-header {
        color: $color_3;
        font-family: 'calibre';
        font-size: 2rem;
        letter-spacing: 0.1rem;
    }

    .experience-header:before {
        content: "02.";
        margin-right: 10px;
        color: $color_1;
        font-family: monospace;
    }

    .experience-header:after {
        content: "";
    display: block;
    height: 1px;
    width: 300px;
    background-color: rgb(45, 57, 82);
    position: relative;
    top: -5px;
    margin-left: 20px;
    }

}


.Project-1 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 50px;
    gap: 10px;
    min-height: auto;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}



.project-1-pic {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 1;
    grid-column: 1 / 8;
    grid-row: 1 / -1;
    border-radius: 4px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    width: 100%;
    height: 22.5rem;
    margin-top: 15%;
    object-fit: fill;
    filter: grayscale(70%);
}

.project-1-pic:hover {
    filter: none;
}


.project-1-rightside {
        position: relative;
        grid-column: 13 / 7;
        grid-row: 1 / -1;
        z-index: 2;
        text-align: right;

}

.feat-header {
    font-size: 13px;
    font-weight: normal;
    color: rgb(100, 255, 218);
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    margin-top: 10px;
    padding-top: 0px;
}

.Proj-name {
    font-size: 28px;
    color: $color_2;
    margin: 0px 0px 20px;
}

.proj-description {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 2;
    background-color: #2d343e;
    color: rgb(168, 178, 209);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    padding: 25px;
    border-radius: 3px;
    .proj-description-text {
        font-size: 13px;
    }
}

.tech-list2 {
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 25px 0px 10px;
    list-style: none;
}

.tech-items {
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-size: 13px;
    color: rgb(136, 146, 176);
    margin-right: 20px;
    margin-bottom: 7px;
    white-space: nowrap;
}


.project-2-rightside {
    position: relative;
    grid-column: 1 / 7;
    grid-row: 1 / -1;
    z-index: 2;
    text-align: left;

}

.project-2-pic {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 1;
    grid-column: 13 / 6;
    grid-row: 1 / -1;
    border-radius: 4px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    width: 100%;
    height: 22.5rem;
    margin-top: 15%;
    object-fit: fill;
    filter: grayscale(70%);
}

.project-2-pic:hover {
    filter: none;
}


.tech-list2-2 {
    -webkit-box-pack: end;
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 25px 0px 10px;
    list-style: none;
}

.Project-2 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    min-height: auto;
    visibility: visible;
    opacity: 0.5;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.go-to {
    fill: $color_2;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.go-to:hover {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    fill:$color_1;
    display: inline-block;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transform: translateY(-3px);
}

.noteworthy-projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    position: relative;
    gap: 15px;
}

.noteworthy-container {
    max-width: 1000px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px auto;
    padding: 150px 0px;
    margin-top: 20px;
}

.header-4 {
    font-weight: 600;
    color: $color_3;
    margin: 0px 0px 10px;
    font-size: 32px;
    margin: 0px auto;
    font-family: 'calibre';
    margin-bottom: 10%;

}

.note-1 {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    height: 80%;
}

.note-1:hover {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    fill:$color_1;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transform: translateY(-3px);
}

.inner-cont {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    background-color: #2d343e;
    padding: 2rem 1.75rem;
    border-radius: 3px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.folder-svg {
    fill: $color_1;
    width: 15%;
    margin-right: 80%;
}

.top-logo-cont {
    display: flex;
}

h5 {
    color: $color_3;
    font-size: 22px;
    
}

.descript-note {
    color: rgb(181, 181, 181);
}

.tech-list {
    color: rgb(169, 169, 169);
}

.final-part {
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    display: block;
    color: $color_1;
    font-size: 16px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 10px 0px 20px;
    text-align: center;
}

.final-part::before {
    content: "03.";
    margin-right: 10px;
    font-family: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", "Lucida Console", Monaco, monospace;
    font-weight: normal;
    color: $color_1;
    font-size: 20px;
    position: relative;
    bottom: 4px;
    text-align: center;
}

.final-text {
    width: 75%;
    font-family: Monaco, monospace;
    text-align: center;
    color: $color_3;
}

.contact-email {
    color: $color_1;
    background-color: transparent;
    font-size: 14px;
    font-family: monospace;
    line-height: 1;
    cursor: pointer;
    margin-top: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: $color_1;
    border-image: initial;
    border-radius: 3px;
    padding: 1.25rem 1.75rem;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    display: inline-block;
    text-decoration-skip-ink: auto;
    position: relative;
    cursor: pointer;
    color: $color_1;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    margin-bottom: 25%;
}

.contact-email:hover {
    background-color: $color_1;
    color: white;
}

.final-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

    .credits {
        font-family: monospace;
        color: whitesmoke;
        background-color: #1a1e23;
        padding-bottom: 2.6%;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        padding-top: 2.5%;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }